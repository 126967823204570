



































































































































import Vue from 'vue'

export default Vue.extend({
  name: 'App',
  data: function () {
    return {
      drawer: null,
      navLists: [
        {
          name: 'ホーム',
          icon: 'mdi-home',
          link: '/'
        },
        {
          name: '電子部品',
          icon: 'mdi-chip',
          active: false,
          lists: [
            {
              name: '電子部品',
              link: '/electronics'
            },
            {
              name: '注目の電子部品',
              link: '/electronics_focus'
            },
            {
              name: '部品在庫検索',
              link: '/search'
            },
            {
              name: '取引メーカー',
              link: '/maker'
            }
          ]
        },
        {
          name: '光通信関連部品',
          icon: 'mdi-cable-data',
          active: false,
          lists: [
            {
              name: '光アッテネータ',
              link: '/attenuator'
            },
            {
              name: '光パッチコード在庫検索',
              link: '/opt_custom_order'
            },
            {
              name: '光パッチコードカスタムオーダー',
              link: '/opt_custom_order'
            },
            {
              name: '光アダプタ',
              link: '/adapter'
            },
            {
              name: '光コネクタ',
              link: '/opt_connector'
            },
            {
              name: 'アクセサリ',
              link: '/accessories'
            // },
            // {
            //   name: 'その他',
            //   link: '/originals'
            }
          ]
        },
        {
          name: '精密切削加工',
          icon: 'mdi-screwdriver',
          link: '/component'
        },
        {
          name: '代表ご挨拶',
          icon: 'mdi-account-tie',
          link: '/president_greeting'
        },
        {
          name: 'スタッフの声',
          icon: 'mdi-account-multiple',
          link: '/staff_voice'
        },
        {
          name: 'お問い合わせ',
          icon: 'mdi-phone',
          link: '/toiawase'
        },
        {
          name: 'アクセスマップ',
          icon: 'mdi-map-marker ',
          link: '/accessmap'
        }
      ]
    }
  },
  methods: {
    createTitleDesc (routeInstance: any) {
      // titleを設定する処理
      if (routeInstance.meta.title) {
        var setTitle = routeInstance.meta.title + ' | 株式会社東京アドコム - 電子部品関連、光通信関連部品、精密度加工部品'
        document.title = setTitle
      } else {
        document.title = '株式会社東京アドコム - 電子部品関連、光通信関連部品、精密度加工部品'
      }
      // メタタグのdescription設定処理
      if (routeInstance.meta.desc) {
        var setDesc = routeInstance.meta.desc
        document.querySelector("meta[name='description']")!.setAttribute('content', setDesc + '私たち東京アドコムは電子部品関連、光通信関連部品、精密度加工部品を中心に、その他新製品や終息品などの入手困難品も独自のルートからお探しいたします。是非、御社のビジネスパートナーとして御利用ください。')
      } else {
        document.querySelector("meta[name='description']")!.setAttribute('content', '私たち東京アドコムは電子部品関連、光通信関連部品、精密度加工部品を中心に、その他新製品や終息品などの入手困難品も独自のルートからお探しいたします。是非、御社のビジネスパートナーとして御利用ください。')
      }
    },
    menuClose () {
      this.navLists.forEach((navList) => (navList.active = false))
    }
  },
  mounted: function () {
    var routeInstance = this.$route
    this.createTitleDesc(routeInstance)
  },
  watch: {
    '$route' (routeInstance) {
      this.createTitleDesc(routeInstance)
    }
  }
})
