import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueHead from 'vue-head'

// import VueGtag from 'vue-gtag'
// import axios from 'axios'
// import VueAxios from 'vue-axios'

Vue.config.productionTip = false

Vue.use(VueHead)

// Vue.use(
//   VueGtag,
//   {
//     config: { id: 'G-1W2X2PBB8S' }
//   },
//   router
// )

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
