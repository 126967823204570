


































































































































import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'Home',
  data () {
    return {
      headers: [
        { text: '日付', value: 'public_date' },
        { text: 'カテゴリ', value: 'category' },
        { text: '内容', value: 'text' }
      ],
      recentInfomationList: [],
      categoryType: [
        { info: 'お知らせ' },
        { news: 'ニュース' },
        { new_product: '新製品' }
      ]
    }
  },
  components: {
  },
  // props: {
  //   recentInfomationList: {
  //     type:[Array],
  //     default: []
  //   },
  // },
  mounted: async function () {
    try {
      const response = await axios.get('/v1/information.php')
      this.recentInfomationList = response.data.data
    } catch (e) {
      console.log('ニュースの取得に失敗：', this.recentInfomationList)
      console.error(e)
    }
  },
  methods: {
    addInformation: async function () {
      try {
        this.recentInfomationList = await axios.get('/v1/information.php')
      } catch (e) {
        console.log('ニュースの取得に失敗：', this.recentInfomationList)
        console.error(e)
      }
    }
  }
})
