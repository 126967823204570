import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm';
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'ホーム',
    component: Home
  },
  {
    path: '/president_greeting',
    name: '代表 ご挨拶',
    meta: { title: '代表 ご挨拶', desc: '代表 ご挨拶。' },
    component: () => import('../views/PresidentGreeting.vue')
  },
  {
    path: '/staff_voice',
    name: 'スタッフの声',
    meta: { title: 'スタッフの声', desc: 'スタッフの声。' },
    component: () => import('../views/StaffVoice.vue')
  },
  {
    path: '/business',
    name: '事業内容',
    meta: { title: '事業内容', desc: '事業内容。' },
    component: () => import('../views/Business.vue')
  },
  {
    path: '/company',
    name: '会社情報',
    meta: { title: '会社情報', desc: '会社情報。' },
    component: () => import('../views/Company.vue')
  },
  {
    path: '/electronics',
    name: '電子部品',
    meta: { title: '電子部品', desc: '電子部品。' },
    component: () => import('../views/Electronics.vue')
  },
  {
    path: '/electronics_focus',
    name: '注目の電子部品',
    meta: { title: '注目の電子部品', desc: '注目の電子部品。' },
    component: () => import('../views/ElectronicsFocus.vue')
  },
  {
    path: '/attenuator',
    name: '光アッテネーター',
    meta: { title: '光アッテネーター', desc: '光アッテネーター。' },
    component: () => import('../views/Attenuator.vue')
  },
  {
    path: '/search',
    name: '電子部品在庫検索',
    meta: { title: '電子部品在庫検索', desc: '電子部品在庫検索。' },
    component: () => import('../views/Search.vue')
  },
  {
    path: '/maker',
    name: '取り扱いメーカー',
    meta: { title: '取り扱いメーカー', desc: '取り扱いメーカー。' },
    component: () => import('../views/Maker.vue')
  },
  {
    path: '/networking',
    name: '光通信関連部品',
    meta: { title: '光通信関連部品', desc: '光通信関連部品。' },
    component: () => import('../views/Networking.vue')
  },
  {
    path: '/opt_search',
    name: '光パッチコード在庫検索',
    meta: { title: '光パッチコード在庫検索', desc: '光パッチコード在庫検索。' },
    component: () => import('../views/OptSearch.vue')
  },
  {
    path: '/opt_custom_order',
    name: '光パッチコードカスタムオーダー',
    meta: { title: '光パッチコードカスタムオーダー', desc: '光パッチコードカスタムオーダー。' },
    component: () => import('../views/OptCustomOrder.vue')
  },
  {
    path: '/adapter',
    name: '光アダプタ',
    meta: { title: '光アダプタ', desc: '光アダプタ。' },
    component: () => import('../views/Adapter.vue')
  },
  {
    path: '/opt_connector',
    name: '光コネクタ',
    meta: { title: '光コネクタ', desc: '光コネクタ。' },
    component: () => import('../views/OptConnector.vue')
  },
  {
    path: '/accessories',
    name: 'アクセサリ',
    meta: { title: 'アクセサリ', desc: 'アクセサリ。' },
    component: () => import('../views/Accessories.vue')
  },
  {
    path: '/toiawase',
    name: 'お問い合わせ',
    meta: { title: 'お問い合わせ', desc: 'お問い合わせ。' },
    component: () => import('../views/Toiawase.vue')
  },
  {
    path: '/accessmap',
    name: 'アクセスマップ',
    meta: { title: 'アクセスマップ', desc: 'アクセスマップ。' },
    component: () => import('../views/AccessMap.vue')
  },
  {
    path: '/component',
    name: '精密切削加工',
    meta: { title: '精密切削加工', desc: '精密切削加工。' },
    component: () => import('../views/Component.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

export default router

Vue.use(VueGtm, {
  id: 'GTM-KBJRGFX'
});
